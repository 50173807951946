import styled from '@emotion/styled'
import { faBars, faExternalLink, faHomeLg, faX } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Breakpoint from '../../../Breakpoint'
import UserContext from '../../../context/UserContext'
import { isTokenExpired } from '../../../services/jwt.service'
import MainLink from '../MainLink'
import { ExternalLink } from './Header'

export const MainHeaderNav = ({ selectedLngLowerCase }: { selectedLngLowerCase: string }) => {
    const user = useContext(UserContext)
    const tokenMember = localStorage.getItem('tokenMember')?.split('Bearer ')[1] ?? ''
    let tokenExpired
    if (tokenMember) tokenExpired = isTokenExpired(tokenMember)

    const [isShowMenuMobile, setIsShowMenuMobile] = useState(false)

    console.log(tokenExpired)

    if (tokenExpired) localStorage.removeItem('tokenMember')

    const { t } = useTranslation('translation')

    return (
        <NavContainer>
            <MainNav>
                <MainLink
                    to={`/${selectedLngLowerCase}/`}
                    className={location.pathname === `/${selectedLngLowerCase}/` ? 'active' : ''}
                >
                    <FontAwesomeIcon icon={faHomeLg} /> {t('header.menu.home')}
                </MainLink>
                <MainLink
                    to={`/${selectedLngLowerCase}/horaires-et-tarifs`}
                    className={location.pathname === `/${selectedLngLowerCase}/horaires-et-tarifs` ? 'active' : ''}
                >
                    {t('header.menu.planning')}
                </MainLink>
                <ExternalLink href="https://adherer.ffbad.club/BVA59" target="_blank" rel="noreferrer">
                    {t('header.menu.registration')} <FontAwesomeIcon icon={faExternalLink} />
                </ExternalLink>
                <MainLink
                    to={`/${selectedLngLowerCase}/competitions`}
                    className={location.pathname === `/${selectedLngLowerCase}/competitions` ? 'active' : ''}
                >
                    {t('header.menu.competition')}
                </MainLink>
                <MainLink
                    to={`/${selectedLngLowerCase}/contact`}
                    className={location.pathname === `/${selectedLngLowerCase}/contact` ? 'active' : ''}
                >
                    {t('header.menu.contact')}
                </MainLink>
                <MainLink
                    to={`/${selectedLngLowerCase}/login`}
                    className={location.pathname === `/${selectedLngLowerCase}/login` ? 'active' : ''}
                >
                    {t('header.menu.login')}
                </MainLink>
            </MainNav>
            <IconBurger onClick={() => setIsShowMenuMobile(true)}>
                <FontAwesomeIcon icon={faBars} />
            </IconBurger>

            {isShowMenuMobile && (
                <MenuMobile>
                    <CloseBtnContainer onClick={() => setIsShowMenuMobile(false)}>
                        <FontAwesomeIcon icon={faX} />
                    </CloseBtnContainer>
                    <MainLink
                        to={`/${selectedLngLowerCase}/`}
                        onClick={() => setIsShowMenuMobile(false)}
                        className={location.pathname === `/${selectedLngLowerCase}/` ? 'active' : ''}
                    >
                        <FontAwesomeIcon icon={faHomeLg} /> {t('header.menu.home')}
                    </MainLink>
                    <MainLink
                        to={`/${selectedLngLowerCase}/horaires-et-tarifs`}
                        className={location.pathname === `/${selectedLngLowerCase}/horaires-et-tarifs` ? 'active' : ''}
                        onClick={() => setIsShowMenuMobile(false)}
                    >
                        {t('header.menu.planning')}
                    </MainLink>
                    <ExternalLink href="https://adherer.ffbad.club/BVA59" target="_blank" rel="noreferrer">
                        {t('header.menu.registration')} <FontAwesomeIcon icon={faExternalLink} />
                    </ExternalLink>
                    <MainLink
                        to={`/${selectedLngLowerCase}/competitions`}
                        className={location.pathname === `/${selectedLngLowerCase}/competitions` ? 'active' : ''}
                        onClick={() => setIsShowMenuMobile(false)}
                    >
                        {t('header.menu.competition')}
                    </MainLink>
                    <MainLink
                        to={`/${selectedLngLowerCase}/contact`}
                        className={location.pathname === `/${selectedLngLowerCase}/contact` ? 'active' : ''}
                        onClick={() => setIsShowMenuMobile(false)}
                    >
                        {t('header.menu.contact')}
                    </MainLink>
                    <MainLink
                        to={`/${selectedLngLowerCase}/login`}
                        className={location.pathname === `/${selectedLngLowerCase}/login` ? 'active' : ''}
                        onClick={() => setIsShowMenuMobile(false)}
                    >
                        {t('header.menu.login')}
                    </MainLink>
                </MenuMobile>
            )}
        </NavContainer>
    )
}

const NavContainer = styled.div`
    display: flex;
    width: 70%;
    box-sizing: border-box;

    @media (max-width: ${Breakpoint.TABLET_L}) {
        width: 16%;
        justify-content: flex-end;
    }
`

const IconBurger = styled.div`
    display: none;

    @media (max-width: ${Breakpoint.TABLET_L}) {
        display: flex;
        align-items: center;
        margin-right: 8%;
        cursor: pointer;
        font-size: 4rem;
        color: var(--primary-color);
    }
`

const CloseBtnContainer = styled.div`
    display: flex;
    width: 100vw;
    justify-content: flex-end;
    padding: 24px 24px 24px 0;
    color: var(--primary-color);
    box-sizing: border-box;
    font-size: 3rem;

    & .fa-x {
        cursor: pointer;
    }
`

const MenuMobile = styled.nav`
    overflow: auto;
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: white;
    align-items: center;
    font-weight: 700;
    width: 100%;
    z-index: 1000;

    & a {
        padding: 8px 0;
        margin-bottom: 24px;
        text-decoration: none;
        color: black;
        font-size: 2rem;
        transition: scale 0.4s;

        &:hover {
            color: var(--primary-color);
            scale: 1.2;
        }

        &.active {
            color: var(--primary-color);
            border-bottom: 2px solid var(--primary-color);
        }
    }
`

const MainNav = styled.nav`
    display: flex;
    margin-right: 8%;
    align-items: center;
    font-weight: 700;
    justify-content: space-around;
    width: 100%;

    @media (max-width: ${Breakpoint.TABLET_L}) {
        display: none;
    }
`
