import styled from '@emotion/styled'
import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { jwtDecode } from 'jwt-decode'
import { useContext, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { resetPassword } from '../../api/admin-user.api'
import Breakpoint from '../../Breakpoint'
import Label from '../../components/form/Label'
import catchError from '../../components/global/CatchError'
import Title from '../../components/global/Title'
import { sweetAlertSuccess } from '../../components/sweetalert/Alert'
import { SERVER_URL } from '../../config'
import UserContext from '../../context/UserContext'
import { useLangSelected } from '../../hooks/useLangSelected'
import { DecodedToken, roles } from '../../types/user.type'
import { AdminForm } from './users/AdminUsersForm'

enum LoginTypeEnum {
    MEMBER = 'member',
    COACH = 'coach',
    ADMIN = 'admin',
}

const Login = () => {
    const [userConnect, setUserConnect] = useState('')
    const [password, setPassword] = useState('')
    // Function to determine default login type based on existing tokens
    const getDefaultLoginType = () => {
        if (!localStorage.getItem('tokenMember')) return LoginTypeEnum.MEMBER
        if (!localStorage.getItem('tokenCoach')) return LoginTypeEnum.COACH
        if (!localStorage.getItem('tokenAdmin')) return LoginTypeEnum.ADMIN
        return undefined
    }

    const [type, setType] = useState<LoginTypeEnum | undefined>(getDefaultLoginType())

    const user = useContext(UserContext)
    const navigate = useNavigate()
    const lng = useLangSelected()
    const { t } = useTranslation('translation')

    const [showResetPasswordModal, setShowResetPasswordModal] = useState(false)
    const [pseudo, setPseudo] = useState('')

    const { mutate: resetPasswordFn } = useMutation({
        mutationFn: (pseudo: string) => resetPassword(pseudo),
    })

    const hasAdminToken = !!localStorage.getItem('tokenAdmin')
    const hasMemberToken = !!localStorage.getItem('tokenMember')
    const hasCoachToken = !!localStorage.getItem('tokenCoach')

    const login = () => {
        axios
            .post(`${SERVER_URL}/users/login`, { userConnect, password, type })
            .then((res) => {
                const tokenDecoded: DecodedToken = jwtDecode(res.data)

                if (tokenDecoded.role === roles.ROLE_ADMIN) {
                    localStorage.setItem('tokenAdmin', 'Bearer ' + res.data)
                    user.tokenAdmin = localStorage.getItem('tokenAdmin') ?? ''
                    user.adminData = tokenDecoded

                    sweetAlertSuccess(t('page.login.connection-success')).then(() => {
                        navigate(`/${lng}/bva-admin`)
                        window.location.reload()
                    })
                } else if (tokenDecoded.role === roles.ROLE_MEMBER) {
                    localStorage.setItem('tokenMember', 'Bearer ' + res.data)
                    user.tokenMember = localStorage.getItem('tokenMember') ?? ''
                    user.memberData = tokenDecoded

                    sweetAlertSuccess(t('page.login.connection-success')).then(() => {
                        navigate(`/${lng}/bva-membre`)
                        window.location.reload()
                    })
                } else {
                    localStorage.setItem('tokenCoach', 'Bearer ' + res.data)
                    user.tokenCoach = localStorage.getItem('tokenCoach') ?? ''
                    user.coachData = tokenDecoded

                    sweetAlertSuccess(t('page.login.connection-success')).then(() => {
                        navigate(`/${lng}/bva-coach`)
                        window.location.reload()
                    })
                }
            })
            .catch((err) => {
                catchError(err.response.data)
            })
    }

    return (
        <LoginFormContainer>
            {showResetPasswordModal && (
                <Modal centered show={true} onHide={() => setShowResetPasswordModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            {t('page.login.reset-password')}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <AdminForm>
                            <Form.Group className="mb-3">
                                <Label>{t('page.login.pseudo-received-by-email')}</Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t('page.login.pseudo-placeholder')}
                                    onChange={(e) => setPseudo(e.target.value)}
                                    value={pseudo}
                                />
                            </Form.Group>
                            <StyledButton
                                variant="primary"
                                type="button"
                                disabled={!pseudo}
                                onClick={() => {
                                    resetPasswordFn(pseudo)
                                    setPseudo('')
                                    setShowResetPasswordModal(false)
                                    sweetAlertSuccess(t('page.login.reset-password-email-sent'))
                                }}
                            >
                                {t('page.login.send-email')}
                            </StyledButton>
                        </AdminForm>
                    </Modal.Body>
                </Modal>
            )}
            <LoginFormWrapper>
                <TitleLogin>
                    {t('page.login.connexion')}
                    {type && (
                        <SelectCustom onChange={(e) => setType(e.target.value as LoginTypeEnum)} value={type}>
                            {!hasMemberToken && <option value={LoginTypeEnum.MEMBER}>{t('page.login.member')}</option>}
                            {!hasCoachToken && <option value={LoginTypeEnum.COACH}>{t('page.login.coach')}</option>}
                            {!hasAdminToken && <option value={LoginTypeEnum.ADMIN}>{t('page.login.admin')}</option>}
                        </SelectCustom>
                    )}
                </TitleLogin>
                <LoginForm>
                    <RedirectButtonsContainer>
                        {hasMemberToken && (
                            <StyledButton
                                variant="primary"
                                type="button"
                                onClick={() => navigate(`/${lng}/bva-membre`)}
                            >
                                {t('page.login.go-to-member-space')}
                            </StyledButton>
                        )}
                        {hasCoachToken && (
                            <StyledButton variant="primary" type="button" onClick={() => navigate(`/${lng}/bva-coach`)}>
                                {t('page.login.go-to-coach-space')}
                            </StyledButton>
                        )}
                        {hasAdminToken && (
                            <StyledButton variant="primary" type="button" onClick={() => navigate(`/${lng}/bva-admin`)}>
                                {t('page.login.go-to-admin-space')}
                            </StyledButton>
                        )}
                    </RedirectButtonsContainer>

                    {type && (
                        <>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Label>{t('page.login.email-or-pseudo')}</Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t('page.login.enter-email-or-pseudo')}
                                    onChange={(e) => setUserConnect(e.target.value)}
                                    value={userConnect}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Label>{t('page.login.password')}</Label>
                                <Form.Control
                                    type="password"
                                    placeholder={t('page.login.enter-password')}
                                    onChange={(e) => setPassword(e.target.value)}
                                    value={password}
                                />
                            </Form.Group>
                            <StyledLinkAByButton
                                variant="link"
                                type="button"
                                onClick={() => setShowResetPasswordModal(true)}
                            >
                                {t('page.login.forgot-password')}
                            </StyledLinkAByButton>
                            <StyledButton
                                variant="primary"
                                type="button"
                                disabled={!userConnect || !password}
                                onClick={() => login()}
                            >
                                {t('page.login.connect')}
                            </StyledButton>
                        </>
                    )}
                </LoginForm>
            </LoginFormWrapper>
        </LoginFormContainer>
    )
}

const SelectCustom = styled(Form.Select)`
    cursor: pointer;
    margin-left: 8px;
    font-size: 1.6rem;
`

const TitleLogin = styled(Title)`
    display: flex;
`

const StyledLinkAByButton = styled(Button)`
    display: flex;
    margin: 8px 0;
    padding: 0;
    font-size: 1.6rem;
`

export const StyledButton = styled(Button)`
    font-size: 1.6rem;

    &:active {
        transform: scale(0.95);
    }
`

const LoginForm = styled(Form)`
    width: 100%;

    input {
        font-size: 1.6rem;
    }
`

const LoginFormContainer = styled.div`
    display: flex;
    width: 100%;
    height: calc(100vh - 166px);
    justify-content: center;
    align-items: center;

    @media (max-width: ${Breakpoint.LAPTOP_XS}) {
        padding: 16px;
    }
`

const LoginFormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 35%;
    border-radius: 6px;
    padding: 24px;
    background-color: white;
    justify-content: center;
    align-items: center;

    @media (max-width: ${Breakpoint.TABLET_L}) {
        width: 100%;
    }
`

const RedirectButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 16px;
    width: 100%;
`

export default Login
