import styled from '@emotion/styled'
import dayjs from 'dayjs'
import parse from 'html-react-parser'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Breakpoint from '../../Breakpoint'
import images from '../../assets/img/images'
import UserContext from '../../context/UserContext'
import { useLangSelected } from '../../hooks/useLangSelected'
import { CompetitionModel } from '../../types/competition.type'
import sweetAlert from '../sweetalert/Alert'
import TooltipBva from './TooltipBva'

const CompetitionEvent = ({
    title,
    subtitle,
    description,
    image,
    linkBadnet,
    linkHelloAsso,
    minPrice,
    limitDateHelloAsso,
}: CompetitionModel) => {
    const user = useContext(UserContext)

    const navigate = useNavigate()
    const { t } = useTranslation('translation')
    let lng = useLangSelected()

    const actionBtnTournamentMoney = () => {
        if (linkHelloAsso) {
            if (user.memberData?.tournamentMoney >= minPrice)
                return (
                    <LinkCompetition href={linkHelloAsso} target="_blank" rel="noreferrer">
                        {t('page.competition.tournament-account-register')}
                    </LinkCompetition>
                )
            else if (user?.memberData?.tournamentMoney < minPrice)
                return (
                    <TooltipBva tootipText="Tu n'as pas assez sur ton compte tournoi pour ce tournoi.">
                        <LinkCompetitionBtn disabled={user?.memberData?.tournamentMoney < minPrice}>
                            {t('page.competition.tournament-account-register')}
                        </LinkCompetitionBtn>
                    </TooltipBva>
                )
            return (
                <LinkCompetitionBtn
                    onClick={() =>
                        !user.memberData &&
                        sweetAlert(t('page.competition.need-login'), '', 'info').then(() => navigate(`/${lng}/login`))
                    }
                >
                    {t('page.competition.tournament-account-register')}
                </LinkCompetitionBtn>
            )
        }
    }

    return (
        <CompetitionContainer>
            <CoverImageContainer image={image || images.logoBVA} />
            <CompetitionWrapper>
                <CompetitionTitle>{title}</CompetitionTitle>
                <CompetitionSubtitle>{subtitle}</CompetitionSubtitle>
                <CompetitionDescription>{parse(description)}</CompetitionDescription>
            </CompetitionWrapper>
            <LinkWrapper>
                {linkBadnet && (
                    <LinkCompetition href={linkBadnet} target="_blank" rel="noreferrer">
                        {t('page.competition.badnet-register')}
                    </LinkCompetition>
                )}

                {!!dayjs(limitDateHelloAsso).isAfter(dayjs()) && <>{actionBtnTournamentMoney()}</>}
            </LinkWrapper>
        </CompetitionContainer>
    )
}

type CoverImageContainerProps = {
    image: string
}

const LinkWrapper = styled.div`
    position: absolute;
    bottom: 0;
    right: 8px;

    @media (max-width: ${Breakpoint.LAPTOP_XS}) {
        position: inherit;
    }
`

const LinkCompetition = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-color);
    color: white;
    text-decoration: none;
    padding: 8px;
    height: 36px;
    border-radius: 4px;
    transition: all 0.4s;
    margin-bottom: 8px;

    &:hover {
        opacity: 0.7;
        color: white;
    }
`

const LinkCompetitionBtn = styled.button`
    appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-color);
    color: white;
    text-decoration: none;
    padding: 8px;
    height: 36px;
    border-radius: 4px;
    transition: all 0.4s;
    margin-bottom: 8px;
    border: none;
    width: 100%;

    ${({ disabled }) => disabled && 'opacity: 0.2;'}

    ${({ disabled }) =>
        !disabled &&
        `&:hover {
        opacity: 0.7;
        color: white;
    }`}
`

const CoverImageContainer = styled.div`
    ${({ image }: CoverImageContainerProps) => `
        position: relative;
        min-width: 30%;
        background-image: url(${image});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 100%;
        margin-right: 16px;
    `}
`

const CompetitionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-right: 16px;
    margin-bottom: 8px;
`

const CompetitionTitle = styled.h2`
    font-size: 2.6rem;
    margin-top: 16px;
    color: var(--primary-color);
    font-family: 'Roboto Semi Bold';
`

const CompetitionSubtitle = styled.p`
    margin-left: 8px;
    font-size: 2rem;
    margin-bottom: 24px;
    color: #aaa;
    font-family: 'Roboto Semi Bold';
`

const CompetitionDescription = styled.div`
    text-align: justify;
    line-height: 24px;
    overflow: auto;

    @media (max-width: ${Breakpoint.LAPTOP_XS}) {
        height: 100px;
    }
`

const CompetitionContainer = styled.article`
    position: relative;
    width: 100%;
    height: 300px;
    display: flex;
    background: white;
    padding: 16px;
    border-radius: 6px;
    margin-right: 5px;
    margin-bottom: 24px;
    box-sizing: border-box;
    overflow: hidden;

    &:hover {
        box-shadow: 0px 0px 6px black;
    }

    @media (max-width: ${Breakpoint.LAPTOP_XS}) {
        margin-bottom: 16px;
        flex-direction: column;
        height: 500px;
    }
`

export default CompetitionEvent
